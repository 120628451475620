* {
  font-family: 'Dosis', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", cursive;
  font-weight: normal;
  letter-spacing: 3px;
  color: #232323;
}
a {
  text-decoration: none;
}
body {
  background-color: #f8f8f8;
  padding-bottom: 130px;
}
.name {
  font-family: 'Dosis', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", cursive;
  font-weight: normal;
  color: #222222;
  letter-spacing: 3px;
  font-size: 30px;
  text-align: center;
  margin-top: 130px;
  margin-bottom: 6px;

}

.skills {
  margin-top: 130px;
}
.skill-table {
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  border-spacing: 10px;
}

.skill-name {
  text-align: center;
  width: 160px;
}
.skill-diff {
  text-align: left;
  width: 160px;
}
